<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.8rem;">文件标题：</span>
					<el-input v-model="searchinput" placeholder="请输入" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<!-- <el-button type="success" @click="addnew">新建公文处理单</el-button> -->
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="220" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showdetail(scope.row)" type="primary" size="mini">详情</el-button>
							<el-button @click="bohui(scope.row)" type="warning" size="mini">驳回</el-button>
							<el-button @click="postdata(scope.row)" type="success" size="mini">复核</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>

		<!--审核弹出-->
		<el-dialog title="确认复核" :visible.sync="showtc" width="40%" :before-close="setsheng">
			<div>
				<el-form ref="ruleForm" class="demo-ruleForm">
					<el-form-item label="复核意见" required>
						<el-input type="textarea" :rows="4" placeholder="请输入复核意见" v-model="sheng.fsyj">
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setsheng">取 消</el-button>
				<el-button type="success" @click="queren">通 过</el-button>
			</span>
		</el-dialog>



		<detail ref="mydetail"></detail>

	</div>
</template>

<script>
	import detail from '../components/detail.vue'
	import selectusers from '@/components/select_users.vue'
	export default {
		components: {
			detail,
			selectusers
		},
		data() {
			return {
				searchinput: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '文件标题',
						width: ''
					},
					{
						prop: 'fhuser_name',
						name: '复核人',
						width: '120'
					},
					{
						prop: 'qfuser_name',
						name: '签发人',
						width: '120'
					},
					{
						prop: 'jjcd',
						name: '加急程度',
						width: '120',
						type: 'jjcd'
					},
					{
						prop: 'addtime',
						name: '提交时间',
						width: '150'
					}
				],
				tableData: [],
				showtc: false,
				sheng: {
					id: '',
					fsyj: ''
				},
				user_name: '',
				user_id: ''
			}
		},
		mounted() {
			this.gettable()

		},
		methods: {
			bohui(row) {
				this.$prompt('请输入驳回理由', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /\S/,
					inputErrorMessage: '请输入'
				}).then(({
					value
				}) => {
					this.$post({
						url: '/api/matterfwen/bohui',
						params: {
							id: row.id,
							fsyj:value
						}
					}).then((res) => {
						this.$message({
							type: 'success',
							message: '操作成功!'
						})
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {
					
				})
			},
			queren() {
				if (!this.sheng.fsyj) {
					this.$message.warning('请输入复核意见')
					return
				}
				this.$post({
					url: '/api/matterfwen/fuhe',
					params: this.sheng
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
					this.setsheng()
				})
			},
			setsheng() {
				this.sheng = {
					id: '',
					fsyj: ''
				}
				this.showtc = false
			},
			showdetail(row) {
				this.$post({
					url: '/api/matterfwen/details',
					params: {
						id: row.id
					}
				}).then((res) => {
					this.$refs.mydetail.info = res
					this.$refs.mydetail.dialogVisible = true
				})
			},
			postdata(row) {
				// this.showtc = true
				this.sheng.id = row.id
				
				this.$confirm('请确认是否复核通过？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: row.type==3?'/api/matterfwen/fuhe':row.type==4?'/api/matterfwen/tjqianfa':'/api/matterfwen/mszfhtg',
						params: {
							id:row.id
						}
					}).then((res) => {
						this.$message({
							type: 'success',
							message: '操作成功!'
						})
						this.gettable()
						this.setsheng()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				})
			},
			showeidt(row) {
				this.$router.push('/system/get_file/edit?id=' + row.id)
			},
			changswitch(row) {
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/matterfwen/appindex',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize,
						type: '3,4,5',
						tjtype:'2'
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.$router.push('/system/get_file/add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'daifuhe.scss';
</style>